import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";

const columns = [
    {
        accessor: 'payer',
        Header: 'Payer',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { payer, id } = rowData.row.original;
            return (
                <Link to={"/licenses/" + id}>
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{payer}</h5>
                        </div>
                    </Flex>
                </Link>
            );
        }
    },
    {
        accessor: 'network',
        Header: 'Network'
    },
    {
        accessor: 'linesOfService',
        Header: 'Lines of Service'
    },
    {
        accessor: 'effectiveFrom',
        Header: 'Effective From'
    },
    {
        accessor: 'effectiveTo',
        Header: 'Effective To'
    },
    {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        cellProps: {
            className: 'text-end'
        },
        Cell: () => {
            return (
                <CardDropdown>
                    <div className="py-2">
                        <Dropdown.Item href="#!">Edit</Dropdown.Item>
                        <Dropdown.Item href="#!">Delete</Dropdown.Item>
                    </div>
                </CardDropdown>
            );
        }
    }
];

const Licenses = (props) => {
    const [licenses] = useState([]);
    return (
        <AdvanceTableWrapper
            columns={columns}
            data={licenses}
            selection
            sortable
            pagination
            perPage={10}
        >
            <Card className="mb-3">
                <Card.Header>
                    <IndexTableHeader name={"Licensed LOS"} onNewClick={() => {}} table />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Licenses;
