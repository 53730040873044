import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import {useUser} from "../../hooks/useUser";
import FacilityFilters from "./FacilityFilters";

const columns = [
  {
    accessor: "applicableVersion.displayName",
    Header: "Name",
    headerProps: {className : "pe-1"},
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { applicableVersion, id} = rowData.row.original;
      return <LinkCell name={applicableVersion.displayName}
                       to={"/facilities/" + id} hasAvatar />
    }
  },
  {
    accessor: "legalContract.name",
    Header: "Contract",
    headerProps: {className: "pe-1"},
    filter: "includes",
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const {legalContract} = rowData.row.original;
      return <LinkCell name={legalContract.name} to={"contracts/" + legalContract.id} />
    }
  }
];


const Facilities = () => {
  const loader = useLoaderData();
  const data = usePreloadedQuery(graphql`
    query FacilitiesQuery {
      allFacilities {
        id
        legalContract {
          id
          name
        }
        applicableVersion {
          displayName
        }
      }
      allLegalContracts {
        ...ContractFragments_AllContractsDropdown
      }
    }
  `, loader.facilitiesQuery);
  const {hasPermission} = useUser();
  const [showModal, setShowModal] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  return (
      <>
        <AdvanceTableWrapper
            columns={columns}
            data={data.allFacilities}
            sortable
            pagination
            perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <IndexTableHeader name={"Facilities"}
                                onNewClick={() => {setShowModal(true)}}
                                table
                                showNew={hasPermission("Facilities.Create")}
                                showFilters
                                onFiltersClick={() => {setOpenFilters(!openFilters)}}
              />
            </Card.Header>
            <FacilityFilters table
                                 contracts={data.allLegalContracts}
                                 open={openFilters}
            />
            <Card.Body className="p-0">
              <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                  table
                  rowInfo
                  rowCount={data.allFacilities.length}
                  navButtons
                  rowsPerPageSelection
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </>
  );
};

export default Facilities;
