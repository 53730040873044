import React, {useState} from 'react';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import {DateCell, LinkCell} from "../../../components/common/advance-table/CustomCells";
import NewLocationBillingGroupModal from "./NewLocationBillingGroupModal";

const cols = [
    {accessor: 'locationBillingGroup.billingGroup.name',
        Header: 'Billing Group',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { locationBillingGroup } = rowData.row.original;
            return <LinkCell name={locationBillingGroup.billingGroup.name} to={"/billing-groups/" + locationBillingGroup.billingGroup.id} hasAvatar />
        }},
    {accessor: 'lineOfService.lineOfService.name', Header: 'Line of Service'},
    {accessor: 'effectiveFrom', Header: 'Effective From', Cell: DateCell},
    {accessor: 'effectiveTo', Header: 'Effective To', Cell: DateCell}
]
const LocationBillingGroups = ({locationBillingGroups, locationId, availableBillingGroups, availableLinesOfService}) => {
    const data = useFragment(graphql`
    fragment LocationBillingGroups on LocationBillingGroupLineOfService @relay(plural: true) {
        id
        lineOfService {
            lineOfService {
                name
            }
        }
        locationBillingGroup {
            billingGroup {
                name
                id
            }
        }
        effectiveFrom
        effectiveTo
    }`, locationBillingGroups);
    const [showNewModal, setShowNewModal] = useState(false);
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Billing Groups"}
                                         table
                                         showNew={false} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewLocationBillingGroupModal showModal={showNewModal} 
                                          onClose={() => {setShowNewModal(false)}} 
                                          locationId={locationId}
                                          availableBillingGroups={availableBillingGroups}
                                          availableLinesOfService={availableLinesOfService}
            />
        </>
    )
}
export default LocationBillingGroups