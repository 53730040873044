import React, {useState} from 'react';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {BooleanCell, LinkCell} from "../../../components/common/advance-table/CustomCells";
import AdvanceTableFooter from "../../../components/common/advance-table/AdvanceTableFooter";
import NewLocationPractitionerModal from "./NewLocationPractitionerModal";
import {useUser} from "../../../hooks/useUser";

const cols = [
    {accessor: 'practitioner.applicableVersion.displayName',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { practitioner, id } = rowData.row.original;
            return <LinkCell name={practitioner.applicableVersion.displayName} to={"/practitioner-locations/" + id} hasAvatar />
        }},
    {accessor: 'applicableVersion.showInDirectory', Header: "Show In Directory", Cell:BooleanCell},
]
const LocationPractitioners = ({locationId, practitioners, allPractitioners, availableLineOfServices}) => {
    const data = useFragment(graphql`
        fragment LocationPractitioners_Practitioners on PractitionerLocation @relay(plural: true) {
            id
            practitioner {
                applicableVersion {
                    displayName
                }
            }
            applicableVersion {
                showInDirectory
                acceptingNewPatients
            }
        }
    `, practitioners)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Practitioners"}
                                         table
                                         showNew={hasPermission("ProviderLocations.Create")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter table
                                            rowCount={data.length}
                                            rowInfo
                                            navButtons
                                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewLocationPractitionerModal showModal={showNewModal} 
                                          onClose={() => {setShowNewModal(false)}}
                                          allPractitioners={allPractitioners}
                                          availableLinesOfService={availableLineOfServices}
                                          locationId={locationId} 
            />
        </>
    )
}
export default LocationPractitioners