/**
 * @generated SignedSource<<dd56a13431a59af64d721ec91088513d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LocationBillingGroup",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalContractLineOfService",
      "kind": "LinkedField",
      "name": "lineOfService",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LineOfService",
          "kind": "LinkedField",
          "name": "lineOfService",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocationBillingGroup",
      "kind": "LinkedField",
      "name": "locationBillingGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "LocationVersion",
              "kind": "LinkedField",
              "name": "applicableVersion",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effectiveFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effectiveTo",
      "storageKey": null
    }
  ],
  "type": "LocationBillingGroupLineOfService",
  "abstractKey": null
};
})();

node.hash = "12ef9ac001543fc3b8b7c112389aade8";

export default node;
