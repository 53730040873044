/**
 * @generated SignedSource<<0dba80d257c1a5d81296e3fc667c107f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "billingGroupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lineOfServiceIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locationId"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationBillingGroupMutationGroup",
    "kind": "LinkedField",
    "name": "locationBillingGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "billingGroupId",
                "variableName": "billingGroupId"
              },
              {
                "kind": "Variable",
                "name": "effectiveFrom",
                "variableName": "effectiveFrom"
              },
              {
                "kind": "Variable",
                "name": "effectiveTo",
                "variableName": "effectiveTo"
              },
              {
                "kind": "Variable",
                "name": "legalContractLineOfServiceIds",
                "variableName": "lineOfServiceIds"
              },
              {
                "kind": "Variable",
                "name": "locationId",
                "variableName": "locationId"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "LocationBillingGroupModifyPayload",
        "kind": "LinkedField",
        "name": "create",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationBillingGroup",
            "kind": "LinkedField",
            "name": "locationBillingGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewLocationBillingGroupModalMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewLocationBillingGroupModalMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "f072cf29f8f50043aa7e98aca894fe5e",
    "id": null,
    "metadata": {},
    "name": "NewLocationBillingGroupModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewLocationBillingGroupModalMutation(\n  $locationId: ID!\n  $billingGroupId: ID!\n  $lineOfServiceIds: [ID!]!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  locationBillingGroup {\n    create(input: {locationId: $locationId, billingGroupId: $billingGroupId, legalContractLineOfServiceIds: $lineOfServiceIds, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      locationBillingGroup {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "376a996f933c6186b9a92634f9f6ebf6";

export default node;
