/**
 * @generated SignedSource<<7e4f43a147d2f2b8391ed7e523b37496>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LocationBillingGroups",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalContractLineOfService",
      "kind": "LinkedField",
      "name": "lineOfService",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LineOfService",
          "kind": "LinkedField",
          "name": "lineOfService",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocationBillingGroup",
      "kind": "LinkedField",
      "name": "locationBillingGroup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingGroup",
          "kind": "LinkedField",
          "name": "billingGroup",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effectiveFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effectiveTo",
      "storageKey": null
    }
  ],
  "type": "LocationBillingGroupLineOfService",
  "abstractKey": null
};
})();

node.hash = "5fb80c9e68166372ee01c9c99b1b4d17";

export default node;
