/**
 * @generated SignedSource<<721f538558ae3e78c2c000ae133f0975>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BillingGroupFragments_BillingGroupDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "BillingGroup",
  "abstractKey": null
};

node.hash = "42da79987db8880f0cf4874a16b5afb9";

export default node;
