import React from 'react';
import {DateCell, LinkCell} from "../../../components/common/advance-table/CustomCells";
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";

const cols = [
    {accessor: 'locationBillingGroup.location.applicableVersion.name',
        Header: 'Billing Group',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { locationBillingGroup } = rowData.row.original;
            return <LinkCell name={locationBillingGroup.location.applicableVersion.name} to={"/locations/" + locationBillingGroup.location.id} hasAvatar />
        }},
    {accessor: 'lineOfService.lineOfService.name', Header: 'Line of Service'},
    {accessor: 'effectiveFrom', Header: 'Effective From', Cell: DateCell},
    {accessor: 'effectiveTo', Header: 'Effective To', Cell: DateCell}
]
const LocationBillingGroup = ({locationBillingGroups}) => {
    const data = useFragment(graphql`
    fragment LocationBillingGroup on LocationBillingGroupLineOfService @relay(plural:true) {
        id
        lineOfService {
            lineOfService {
                name
            }
        }
        locationBillingGroup {
            location {
                id
                applicableVersion {
                    name
                }
            }
        }
        effectiveFrom
        effectiveTo
    }`, locationBillingGroups)
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {}}
                                         title={"Locations"}
                                         table
                                         showNew={false} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}
export default LocationBillingGroup