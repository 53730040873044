/**
 * @generated SignedSource<<0bf54a9d845e993cac808d8400a2a6c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "LegalContract",
  "kind": "LinkedField",
  "name": "legalContract",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FacilitiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "allFacilities",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FacilityVersion",
            "kind": "LinkedField",
            "name": "applicableVersion",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContractFragments_AllContractsDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FacilitiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "allFacilities",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FacilityVersion",
            "kind": "LinkedField",
            "name": "applicableVersion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f0f2ee0c1d39beb03e98eed82489dea",
    "id": null,
    "metadata": {},
    "name": "FacilitiesQuery",
    "operationKind": "query",
    "text": "query FacilitiesQuery {\n  allFacilities {\n    id\n    legalContract {\n      id\n      name\n    }\n    applicableVersion {\n      displayName\n      id\n    }\n  }\n  allLegalContracts {\n    ...ContractFragments_AllContractsDropdown\n    id\n  }\n}\n\nfragment ContractFragments_AllContractsDropdown on LegalContract {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "3b07a62803cd8f6a7b2a0dcb1548e4d4";

export default node;
