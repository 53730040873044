import React from 'react';
import {useLoaderData} from 'react-router-dom';
import {Col, Row} from "react-bootstrap";
import PractitionerInfo from "./PractitionerInfo";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import graphql from "babel-plugin-relay/macro";
import {usePreloadedQuery} from "react-relay";
import PractitionerIdentifiers from "./identifiers/PractitionerIdentifiers";
import {useUser} from "../../hooks/useUser";
import PractitionerLanguages from "./languages/PractitionerLanguages";
import PractitionerVersions from "./versions/PractitionerVersions";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";
import PractitionerContactPoints from "./contactPoints/PractitionerContactPoints";
import PractitionerLocations from "./locations/PractitionerLocations";

const PractitionerDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
    query PractitionerDetailsQuery($id: ID!) {
        node(id: $id) {
            id
            ... on Practitioner {
                ...PractitionerInfo_Practitioner
                applicableVersion {
                    displayName
                }
                legalContract {
                    id
                    name
                    locations {
                        ...LocationFragments_LocationDropdown
                    }
                    linesOfService {
                        ...ContractLinesOfServiceFragments_LinesOfService
                    }
                }
                versions {
                    ...PractitionerVersions_Versions
                }
                languages {
                    ...PractitionerLanguages_Languages
                }
                identifiers {
                    ...PractitionerIdentifiers_Identifiers
                }
                contactPoints {
                    ...PractitionerContactPoints_ContactPoints
                }
                locations {
                    ...PractitionerLocations_Locations
                }
            }
        }
        allIdentifierUseCodes {
            ...Fragments_AllIdentifierUseCodes
        }
        allIdentifierTypes {
            ...Fragments_AllIdentifierTypes
        }
        allGenders {
            ...Fragments_AllGendersDropdown
        }
        allProfessionalDesignations {
            ...Fragments_AllProfessionalDesignationDropdown
        }
        allLanguages {
            ...Fragments_AllLanguagesDropdown
        }
        allEthnicities {
            ...Fragments_AllEthnicitiesDropdown
        }
        allLegalContracts {
            ...ContractFragments_AllContractsDropdown
        }
        allContactPointUseCodes {
            ...Fragments_AllContactPointUseCodesDropdown
        }
        allContactPointSystemCodes {
            ...Fragments_AllContactPointSystemCodesDropdown
        }
    }`, loader.practitionerDetailsQuery);
    const deleteMutation = graphql`
    mutation PractitionerDetails_DeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            id
        }
    }`
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconText={"Provider"} iconType={"user-md"} title={data.node.applicableVersion.displayName} />
            <PractitionerInfo practitioner={data.node} legalContracts={data.allLegalContracts}/>
            <Row>
                <Col>
                    <PractitionerVersions practitionerId={data.node.id} 
                                          versions={data.node.versions} 
                                          allEthnicities={data.allEthnicities} 
                                          allGenders={data.allGenders} 
                                          allProfessionalDesignations={data.allProfessionalDesignations} 
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {hasPermission("ProviderIdentifiers.Read") &&  <PractitionerIdentifiers practitionerId={data.node.id} 
                                             identifiers={data.node.identifiers} 
                                             allIdentifierTypes={data.allIdentifierTypes} 
                                             allIdentifierUseCodes={data.allIdentifierUseCodes} 
                    />}
                </Col>
                <Col>
                    <PractitionerLanguages practitionerId={data.node.id} 
                                           languages={data.node.languages}
                                           allLanguages={data.allLanguages}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {hasPermission("ProviderContactPoints.Read") && <PractitionerContactPoints practitionerId={data.node.id}
                                                                                               contactPoints={data.node.contactPoints}
                                                                                               systemCodes={data.allContactPointSystemCodes}
                                                                                               useCodes={data.allContactPointUseCodes}
                        />}
                </Col>
                <Col>
                    {hasPermission("ProviderLocations.Read") && <PractitionerLocations practitionerId={data.node.id}
                                                                                       allLocations={data.node.legalContract.locations}
                                                                                       locations={data.node.locations}
                                                                                       availableLineOfServices={data.node.legalContract.linesOfService}
                        />}
                </Col>
            </Row>
            {hasPermission("Providers.Delete") && 
                <DeletePanel mutation={deleteMutation} 
                             redirectUrl={"/practitioners"} 
                             thingToDelete={"Provider"} 
                             id={data.node.id}>
                    In order to delete a provider, there must be only 1 version and no other related data.
            </DeletePanel>}
        </>
    );
};

export default PractitionerDetails;
