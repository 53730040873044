import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {usePreloadedQuery} from "react-relay";
import {useLoaderData} from "react-router-dom";
import {useUser} from "../../hooks/useUser";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import PractitionerLocationVersions from "./versions/PractitionerLocationVersions";
import {Col, Row} from "react-bootstrap";
import PractitionerLocationTaxonomies from "./taxonomies/PractitionerLocationTaxonomies";
import PractitionerLocationLineOfService from "./linesOfService/PractitionerLocationLineOfService";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";

const PractitionerLocationDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query PractitionerLocationDetailsQuery($id: ID!) {
            node(id:$id) {
                id
                ... on PractitionerLocation {
                    practitioner {
                        id
                        applicableVersion {
                            displayName
                        }
                        legalContract {
                            linesOfService {
                                ...ContractLinesOfServiceFragments_LinesOfService
                            }
                        }
                    }
                    location {
                        applicableVersion {
                            name
                        }
                    }
                    linesOfService {
                        ...PractitionerLocationLineOfService
                    }
                    versions {
                        ...PractitionerLocationVersions_Versions
                    }
                    taxonomies {
                        ...PractitionerLocationTaxonomies_Taxonomy
                    }
                }
            }
            allTaxonomies {
                ...Fragments_AllTaxonomiesDropdown
            }
        }
    `, loader.practitionerLocationDetailsQuery);
    const deleteMutation = graphql`
    mutation PractitionerLocationDetails_DeleteMutation($id:ID!) {
        deleteNode(input:{id: $id}) {
            id
        }
    }`
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconText={"Provider Location"} 
                               iconType={"user-md"} 
                               title={data.node.practitioner.applicableVersion.displayName + " at " + data.node.location.applicableVersion.name} 
            />
            <Row>
                <Col>
                    <PractitionerLocationVersions practitionerLocationId={data.node.id}
                                                  versions={data.node.versions}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <PractitionerLocationTaxonomies practitionerLocationId={data.node.id}
                                                    taxonomies={data.node.taxonomies}
                                                    allTaxonomies={data.allTaxonomies}
                    />
                </Col>
                <Col>
                    <PractitionerLocationLineOfService practitionerLocationId={data.node.id}
                                                       availableLinesOfService={data.node.practitioner.legalContract.linesOfService}
                                                       linesOfService={data.node.linesOfService}
                    />
                </Col>
            </Row>
            <Row>
                {hasPermission("ProviderLocations.Delete") && <DeletePanel mutation={deleteMutation} 
                                                                           redirectUrl={"/practitioners/" + data.node.practitioner.id} 
                                                                           thingToDelete={"Provider Location"} 
                                                                           id={data.node.id}>
                    To delete a Provider Location, you must have only 1 version, only 1 line of service and no other related data.
                </DeletePanel>}
            </Row>
            
        </>
    )
}
export default PractitionerLocationDetails