/**
 * @generated SignedSource<<afec47970ed195bd649e68e8c3f79797>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PractitionerFragments_PractitionerDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PractitionerVersion",
      "kind": "LinkedField",
      "name": "applicableVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Practitioner",
  "abstractKey": null
};

node.hash = "b60a22afd9f3f1fcd32ba37c12d4461e";

export default node;
