import React from 'react';
import NewModal from "../../../components/common/modals/NewModal";
import {createOptions} from "../../../helpers/utils";
import {useFragment} from "react-relay";
import {billingGroupDropdown} from "../../billingGroups/BillingGroupFragments";
import graphql from "babel-plugin-relay/macro";
import {contractLineOfService} from "../../contracts/ContractLineOfService/ContractLinesOfServiceFragments";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import HorizontalFormCheck from "../../../components/common/horizontal-form-inputs/HorizontalFormCheck";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const NewLocationBillingGroupModal = ({showModal, onClose, locationId, availableBillingGroups, availableLinesOfService}) => {
    const mutation = graphql`
        mutation NewLocationBillingGroupModalMutation($locationId: ID!, $billingGroupId: ID!, $lineOfServiceIds: [ID!]!,
            $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
            locationBillingGroup {
                create(input: {locationId: $locationId, billingGroupId: $billingGroupId, legalContractLineOfServiceIds: $lineOfServiceIds,
                    effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                    locationBillingGroup {
                        id
                    }}}}`
    const billingGroups = createOptions(useFragment(billingGroupDropdown, availableBillingGroups), "id", "name");
    const linesOfService = useFragment(contractLineOfService, availableLinesOfService);
    const defaultValues = {
        locationId: locationId,
        billingGroupId: '',
        lineOfServiceIds: [],
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        billingGroupId: yup.string().required("Billing Group is required"),
        lineOfServiceIds: yup.array().min(1, "At least one line of service is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    return (
        <NewModal showModal={showModal} 
                  onClose={onClose} 
                  title={"New Location Billing Group"}
                  mutation={mutation}
                  methods={methods}
                  redirectUrl={"/location-billing-groups/"}
                  returnedIdKey={"locationBillingGroup.create.locationBillingGroup.id"}
        >
            <HorizontalFormSelect options={billingGroups} controlId={"billingGroupId"} label={"Billing Group"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </NewModal>
    )
}
export default NewLocationBillingGroupModal